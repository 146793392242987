import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import { useEffect } from 'react';
export default function CompanyNames(){
    const {  i18n } = useTranslation(['page']);
    const [imgSrc,setImgSrc] = useState(`url(${process.env.PUBLIC_URL}/assets/company/introduce_company_names_en.png)`);

    const isTab = window.innerWidth <=768 && window.innerWidth > 576
    // console.log(isTab);
    const isMobile = window.innerWidth <= 576
    // computer or mobile 언어
    useEffect(()=> {
        if(isMobile){
            setImgSrc(`url(${process.env.PUBLIC_URL}/assets/company/introduce_company_names_${i18n.language}_mb.png)`)
        }else if(isTab){
            setImgSrc(`url(${process.env.PUBLIC_URL}/assets/company/introduce_company_names_${i18n.language}_768.png)`)
        }else {
            setImgSrc(`url(${process.env.PUBLIC_URL}/assets/company/introduce_company_names_${i18n.language}.png)`)
        }
    },[])

    const WebComponent = () => (
        <>
        {/* <div className='main_company_names_wrapper' style={{background:imgSrc , backgroundSize:'100% 100%', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}></div> */}
        <div className="height_high" style={{width:"100%",height:"100vh"}}><img src={process.env.PUBLIC_URL+`/assets/company/introduce_company_names_${i18n.language}.png`} style={{width:'100%',height:"100%",display:"block", cursor:"auto"}}></img></div>
        </>
        )
    const TabComponent = () => (
        <>
        {/* <div className='main_company_names_wrapper' style={{background:imgSrc , backgroundSize:'100% 100%', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}></div> */}
        <div style={{width:"100%",height:"100%"}}><img src={process.env.PUBLIC_URL+`/assets/company/introduce_company_names_${i18n.language}_768.png`} style={{width:'100%',height:"100%",display:"block", cursor:"auto"}}></img></div>
        </>
    )
    const MobileComponent = () => (
        <>
        {/* <div className='main_company_names_wrapper' style={{background:imgSrc , backgroundSize:'100% 100%', backgroundRepeat:'no-repeat', backgroundPositionX:'center',backgroundPositionY:'top'}}></div> */}
        <div style={{width:"100%",height:"100%"}}><img src={process.env.PUBLIC_URL+`/assets/company/introduce_company_names_${i18n.language}_mb.png`} style={{width:'100%',height:"100%",display:"block", cursor:"auto"}}></img></div>
        </>
        )

    return(
        <>
       {isMobile && <MobileComponent />}
       {isTab && <TabComponent />}
       {!isMobile && !isTab && <WebComponent />}
    </>
    )
}