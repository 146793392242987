//다국어
import { useTranslation } from 'react-i18next'
import i18n from "i18next";
// scrollspy
import Scrollspy from 'react-scrollspy'
export default function Header(){
  const { t, i18n } = useTranslation(['page']);
  const onChangeLang = (event) => {
    const selectedLang = event.target.value;
    if(selectedLang != i18n.language){
      i18n.changeLanguage(selectedLang);
    }
    // console.log(i18n.language);
  };
  let sectionClass = '';

  if (i18n.language === 'jp') {
    sectionClass += ' _jp';
  }else if (i18n.language === 'cn') {
    sectionClass += ' _cn'
  }else if (i18n.language === 'ru') {
    sectionClass += ' _ru'
  } else if (i18n.language === 'vn') {
    sectionClass += ' _vn'
  }

  return (
        <div className={'header' + sectionClass} onClick={onChangeLang}>
        <div className='header_logo'><a href='/#'>Block Square</a></div>
        <div className='header_menu'>
          {/* <Scrollspy items={['company','service','roadmap','contact']}  currentClassName="is-current" offset="10px"> */}
          <p><a href="/#company">{t('company')}</a></p>
          <p><a href="/#service">{t('service')}</a></p>
          {/* <p><a href="#media">{t('media')}</a></p> */}
          <p><a href="/#roadmap">{t('roadmap')}</a></p>
          <p><a href="#contact">Contact us</a></p>
          {/* </Scrollspy> */}
        </div>
        <div className='header_select_lang'>
          <select value={i18n.language} onChange={onChangeLang}>
            <option value='cn'>CN</option>
            <option value='jp'>JP</option>
            <option value='ru'>RU</option>
            <option value='vn'>VN</option>
            <option value='en'>EN</option>
            {/* 다른 언어 옵션들 추가 */}
          </select>
        </div>
      </div>
    )
}