import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";

WebFont.load({
    google: {
        families: ['Abril Fatface','Noto Sans KR:500']
    }
})

export default function Company(){
  const { t, i18n } = useTranslation(['page']);
  // console.log(i18n.language);
  let sectionClass = '';
  if (i18n.language === 'en') {
    sectionClass += ' _en';
  }
  if (i18n.language === 'jp') {
    sectionClass += ' _jp';
  }else if (i18n.language === 'cn') {
    sectionClass += ' _cn'
  }else if (i18n.language === 'ru') {
    sectionClass += ' _ru'
  } else if (i18n.language === 'vn') {
    sectionClass += ' _vn'
  }
  const isMobile = window.innerWidth <= 576;

  if (i18n.language === 'jp') {
    if(isMobile){
      return (
        <>
        <div className={'main_company_section1_wrapper' + sectionClass +" "+ "scroll_content"} id="company">
        <div className="main_company_section1_text_box">
          <h1>About Blocksquare</h1>
          <p>
          {t('main_company_section1_text_box_mb')}
          <br></br>
          {t('main_company_section1_text_box2_mb')}
          <br></br>
          {t('main_company_section1_text_box3_mb')}
          <br></br>
          {t('main_company_section1_text_box4_mb')}
          </p>
        </div>
        <div className="main_company_section1_container">
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature1')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature2')}
            <br></br>
            {t('main_company_section1_feature2_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature3')}
              </h4>
            </div>
            <p>{t('main_company_section1_feature4')}
            <br></br>
            {t('main_company_section1_feature4_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature5')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature6')}<br></br>{t('main_company_section1_feature6_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature7')}
              </h4>
            </div>
            <p>
              {t('main_company_section1_feature8')}
              <br></br>
              {t('main_company_section1_feature8_1')}
            </p>
          </div>
        </div>
        </div>
        <div className={"main_company_section2_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section2_text_box">
            <h1>{t('main_company_section2_text_box1')}</h1>
            <h1>{t('main_company_section2_text_box2')}</h1>
            <h1>{t('main_company_section2_text_box3')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section3_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section3_text_box">
            <h1>{t('main_company_section3_text_box1')}
            <br></br>
            {t('main_company_section3_text_box2')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section4_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section4_text_box">
            <h1>{t('main_company_section4_text_box')}</h1>
            <h1>{t('main_company_section4_text_box2')}</h1>
          </div>
        </div>
        </>
    )
    }
    return (
        <>
        <div className={'main_company_section1_wrapper' + sectionClass +" "+ "scroll_content"} id="company">
        <div className="main_company_section1_text_box">
          <h1>About Blocksquare</h1>
          <p>
          {t('main_company_section1_text_box')}
          <br></br>
          {t('main_company_section1_text_box2')}
          <br></br>
          {t('main_company_section1_text_box2_1')}
          </p>
        </div>
        <div className="main_company_section1_container">
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature1')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature2')}
            <br></br>
            {t('main_company_section1_feature2_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature3')}
              </h4>
            </div>
            <p>{t('main_company_section1_feature4')}
            <br></br>
            {t('main_company_section1_feature4_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature5')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature6')}<br></br>{t('main_company_section1_feature6_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature7')}
              </h4>
            </div>
            <p>
              {t('main_company_section1_feature8')}
              <br></br>
              {t('main_company_section1_feature8_1')}
            </p>
          </div>
        </div>
        </div>
        <div className={"main_company_section2_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section2_text_box">
            <h1>{t('main_company_section2_text_box1')}</h1>
            <h1>{t('main_company_section2_text_box2')}</h1>
            <h1>{t('main_company_section2_text_box3')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section3_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section3_text_box">
            <h1>{t('main_company_section3_text_box1')}
            <br></br>
            {t('main_company_section3_text_box2')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section4_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section4_text_box">
            <h1>{t('main_company_section4_text_box')}</h1>
            <h1>{t('main_company_section4_text_box2')}</h1>
          </div>
        </div>
        </>
    )
  } if (i18n.language === 'ru') {
    return(
    <>
        <div className={'main_company_section1_wrapper' + sectionClass +" "+ "scroll_content"} id="company">
        <div className="main_company_section1_text_box">
          <h1>About Blocksquare</h1>
          <p>
          {t('main_company_section1_text_box')}
          </p>
        </div>
        <div className="main_company_section1_container">
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature1')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature2')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature3')}
              <br></br>
              {t('main_company_section1_feature3_1')}
              </h4>
            </div>
            <p>{t('main_company_section1_feature4')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature5')}
                <br></br>
                {t('main_company_section1_feature5_1')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature6')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature7')}
              <br></br>
              {t('main_company_section1_feature7_1')}
              </h4>
            </div>
            <p>
              {t('main_company_section1_feature8')}
            </p>
          </div>
        </div>
        </div>
        <div className={"main_company_section2_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section2_text_box">
            <h1>{t('main_company_section2_text_box1')}</h1>
            <h1>{t('main_company_section2_text_box2')}</h1>
            <h1>{t('main_company_section2_text_box3')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section3_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section3_text_box">
            <h1>{t('main_company_section3_text_box1')}
            <br></br>
            {t('main_company_section3_text_box1_1')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section4_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section4_text_box">
            <h1>{t('main_company_section4_text_box')}
            </h1>
          </div>
        </div>
        </>
    )
  }  if (i18n.language === 'cn') {
    return(
    <>
        <div className={'main_company_section1_wrapper' + sectionClass +" "+ "scroll_content"} id="company">
        <div className="main_company_section1_text_box">
          <h1>About Blocksquare</h1>
          <p>
          {t('main_company_section1_text_box')}
          <br></br>
          {t('main_company_section1_text_box_1')}
          </p>
        </div>
        <div className="main_company_section1_container">
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature1')}
                <br></br>
                {t('main_company_section1_feature1_1')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature2')}
            <br></br>
            {t('main_company_section1_feature2_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature3')}
              </h4>
            </div>
            <p>{t('main_company_section1_feature4')}
            <br></br>
            {t('main_company_section1_feature4_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature5')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature6')}
            <br></br>
            {t('main_company_section1_feature6_1')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature7')}
              </h4>
            </div>
            <p>
              {t('main_company_section1_feature8')}
              <br></br>
              {t('main_company_section1_feature8_1')}
            </p>
          </div>
        </div>
        </div>
        <div className={"main_company_section2_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section2_text_box">
            <h1>{t('main_company_section2_text_box1')}</h1>
            <h1>{t('main_company_section2_text_box2')}</h1>
            <h1>{t('main_company_section2_text_box3')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section3_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section3_text_box">
            <h1>{t('main_company_section3_text_box1')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section4_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section4_text_box">
            <h1>{t('main_company_section4_text_box')}
            <br></br>
            {t('main_company_section4_text_box_1')}
            </h1>
          </div>
        </div>
    </>
    )
  }
  else if (i18n.language === 'vn' || i18n.language === 'en'){
    return(
    <>
        <div className={'main_company_section1_wrapper' + sectionClass +" "+ "scroll_content"} id="company">
        <div className="main_company_section1_text_box">
          <h1>About Blocksquare</h1>
          <p>
          {t('main_company_section1_text_box')}
          </p>
        </div>
        <div className="main_company_section1_container">
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature1')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature2')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature3')}
              </h4>
            </div>
            <p>{t('main_company_section1_feature4')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img1.png"
                }
                alt="bg"
              ></img>
              <h4>
                {t('main_company_section1_feature5')}
              </h4>
            </div>
            <p>
            {t('main_company_section1_feature6')}
            </p>
          </div>
          <div className="main_company_section1_content">
            <div className="main_company_section1_feature">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/main_company_section1_img2.png"
                }
                alt="bg"
              ></img>
              <h4>
              {t('main_company_section1_feature7')}
              </h4>
            </div>
            <p>
              {t('main_company_section1_feature8')}
            </p>
          </div>
        </div>
        </div>
        <div className={"main_company_section2_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section2_text_box">
            <h1>{t('main_company_section2_text_box1')}</h1>
            <h1>{t('main_company_section2_text_box2')}</h1>
            <h1>{t('main_company_section2_text_box3')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section3_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section3_text_box">
            <h1>{t('main_company_section3_text_box1')}
            </h1>
          </div>
        </div>
        <div className={"main_company_section4_wrapper" + sectionClass +" "+ "scroll_content"}>
          <div className="main_company_section4_text_box">
            <h1>{t('main_company_section4_text_box')}
            </h1>
          </div>
        </div>
    </>
    )
  }
}