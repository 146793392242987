import { useTranslation } from 'react-i18next'
import i18n from "i18next";

export default function Footer(){

    const { t, i18n } = useTranslation(['page']);

    return (
        <div className="footer">
            <p>© Copyright 2024 BlockSquare all rights reserved.</p>
            {/* <p><a href="/terms">
                {t('privacy')}
                </a>
            </p> */}
        </div>
    )
}