import React, { useEffect,useState } from 'react';
export default function ApkDownload(){
    const [isDownloaded, setIsDownloaded] = useState(false);

    useEffect(() => {
        const handleDownload = async () => {
            if (!isDownloaded) {
          try {
            const response = await fetch('/apk/app-china-release.apk');
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'app-china-release.apk';  // 다운로드 파일명 지정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

            // 파일 다운로드 후 1초 뒤에 페이지 닫기
            setTimeout(() => {
              window.close();  // 현재 창을 닫습니다.
            }, 5000);  // 1000 밀리초 = 1초
            setIsDownloaded(true);

          } catch (error) {
            console.error('Download failed:', error);
          }
        }
        };

        handleDownload();
      }, [isDownloaded]);
    return (
        <div>
            <h1>Download will start and page will close shortly...</h1>
        </div>
    )
}