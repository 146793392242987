import ServiceItemDR from "./ServiceItemDR";
import ServiceItemDL from "./ServiceItemDL";
import ServiceItemCR from "./ServiceItemCR";
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";
// 모바일에서만 빼기 
WebFont.load({
    google: {
        families: ['Abril Fatface','Montserrat']
    }
})

export default function Service(){
  const { t, i18n } = useTranslation(['page']);
  let sectionClass = '';
  if (i18n.language === 'en') {
    sectionClass += ' _en';
  }
  if (i18n.language === 'jp') {
    sectionClass += ' _jp';
  }else if (i18n.language === 'cn') {
    sectionClass += ' _cn'
  }else if (i18n.language === 'ru') {
    sectionClass += ' _ru'
  } else if (i18n.language === 'vn') {
    sectionClass += ' _vn'
  }

  if (i18n.language === 'vn') {
    return <>
    <div className={"main_service_introduce_section" + sectionClass +" "+ "scroll_content"} id="service">
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_cr">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
            <h1>Goya Score Chart & RSI</h1>
            {/* <p>{t('ArbitrageTrading_service')}</p> */}
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img className="main_service_introduce_content_img_sm" src={process.env.PUBLIC_URL+'/assets/main_section2_img2.png'}></img>
            <h1>{t('Alarm')}</h1>
            {/* <p>{t('Alarm')}</p> */}
          </div>
        </a>
        </div>
        {/* <div className="main_service_introduce_container_bg_all">
          <a href="#service_cr">
            <div className='main_service_introduce_content' style={{border:'none'}}>
              <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
              <h1>Onebot Score Chart & RSI</h1>
              <p>{t('OnebotScoreChart&RSI_service')}</p>
            </div>
          </a>
        </div> */}
      </div>
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img1.png'}></img>
            <h1>Autobot Deep Lite</h1>
            <p>{t('DeepLite_service')}</p>
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dr">
          <div className='main_service_introduce_content' style={{border:'none'}}>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img4.png'}></img>
            <h1>Autobot Deep Revolution</h1>
            <p>{t('DeepRevolution_service')}</p>
          </div>
        </a>
        </div>
      </div>
      <div className='main_service_introduce_center'>
        <img src={process.env.PUBLIC_URL+'/assets/main_service_introduce_center.png'}></img>
      </div>
    </div>
        {/* <div className={'main_section3' + sectionClass +" "+ "scroll_content"} id="service_at">
          <div className='main_section3_left_container'>
            <div>
              <h1>{t('OnebotArbitrage')}</h1>
              <p>&lt;{t('servie_at1')}&gt;</p>
              <p>{t('servie_at2')}{t('servie_at3')}</p>
              <p>{t('servie_at4')}</p>
            </div>
          </div>
          <div className='main_section3_right_container'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section3_img.png'}></img>
          </div>
        </div> */}
        <ServiceItemCR/>
        <ServiceItemDL/>
        <ServiceItemDR/>
      </>
  }else if (i18n.language === 'jp') {
    return <>
    <div className={"main_service_introduce_section" + sectionClass +" "+ "scroll_content"} id="service">
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_cr">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
            <h1>Goya Score Chart & RSI</h1>
            <p>{t('OnebotScoreChart&RSI_service')}</p>
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img className="main_service_introduce_content_img_sm" src={process.env.PUBLIC_URL+'/assets/main_section2_img2.png'}></img>
            <h1>Goya RSI Signal alarm</h1>
            <p>{t('Alarm')}</p>
          </div>
        </a>
        </div>
        {/* <div className="main_service_introduce_container_bg_all">
          <a href="#service_cr">
            <div className='main_service_introduce_content' style={{border:'none'}}>
              <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
              <h1>Onebot Score Chart & RSI</h1>
              <p>{t('OnebotScoreChart&RSI_service')}</p>
            </div>
          </a>
        </div> */}
    </div>
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img1.png'}></img>
            <h1>Autobot Deep Lite</h1>
            <p>{t('DeepLite_service')}</p>
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dr">
          <div className='main_service_introduce_content' style={{border:'none'}}>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img4.png'}></img>
            <h1>Autobot Deep Revolution</h1>
            <p>{t('DeepRevolution_service')}</p>
          </div>
        </a>
        </div>
      </div>
      <div className='main_service_introduce_center'>
        <img src={process.env.PUBLIC_URL+'/assets/main_service_introduce_center.png'}></img>
      </div>
    </div>
        {/* <div className={'main_section3' + sectionClass +" "+ "scroll_content"} id="service_at">
          <div className='main_section3_left_container'>
            <div>
              <h1>{t('ワンボット')}{t("Arbitrage")}</h1>
              <p>&lt;{t('servie_at1')}&gt;</p>
              <p>{t('servie_at2')}{t('servie_at3')}</p>
            </div>
          </div>
          <div className='main_section3_right_container'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section3_img.png'}></img>
          </div>
        </div> */}
        <ServiceItemCR/>
        <ServiceItemDL/>
        <ServiceItemDR/>
      </>
  }else if (i18n.language === 'cn') {
    return <>
    <div className={"main_service_introduce_section" + sectionClass +" "+ "scroll_content"} id="service">
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_cr">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
            <h1>Goya Score Chart & RSI</h1>
            <p>{t('OnebotScoreChart&RSI_service')}</p>
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img className="main_service_introduce_content_img_sm" src={process.env.PUBLIC_URL+'/assets/main_section2_img2.png'}></img>
            <h1>Goya RSI Signal alarm</h1>
            <p>{t('Alarm')}</p>
          </div>
        </a>
        </div>
        {/* <div className="main_service_introduce_container_bg_all">
          <a href="#service_cr">
            <div className='main_service_introduce_content' style={{border:'none'}}>
              <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
              <h1>Onebot Score Chart & RSI</h1>
              <p>{t('OnebotScoreChart&RSI_service')}</p>
            </div>
          </a>
        </div> */}
      </div>
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img1.png'}></img>
            <h1>Autobot Deep Lite</h1>
            <p>{t('DeepLite_service')}</p>
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dr">
          <div className='main_service_introduce_content' style={{border:'none'}}>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img4.png'}></img>
            <h1>Autobot Deep Revolution</h1>
            <p>{t('DeepRevolution_service')}</p>
          </div>
        </a>
        </div>
      </div>
      <div className='main_service_introduce_center'>
        <img src={process.env.PUBLIC_URL+'/assets/main_service_introduce_center.png'}></img>
      </div>
    </div>
    {/* <div className={'main_section3' + sectionClass +" "+ "scroll_content"} id="service_at">
      <div className='main_section3_left_container'>
        <div>
          <h1>{t('OnebotArbitrage')}</h1>
          <p>&lt;{t('servie_at1')}&gt;</p>
          <p>{t('servie_at2')}{t('servie_at2_1')}</p>
          <p>{t('servie_at3')}</p>
        </div>
      </div>
      <div className='main_section3_right_container'>
        <img src={process.env.PUBLIC_URL+'/assets/main_section3_img.png'}></img>
      </div>
    </div> */}
    <ServiceItemCR/>
    <ServiceItemDL/>
    <ServiceItemDR/>
    </>;
  }
  else {
    return <>
    <div className={"main_service_introduce_section" + sectionClass +" "+ "scroll_content"} id="service">
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_cr">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
            <h1>{t('OnebotScoreChart&RSI')}</h1>
            {/* <p>{t('ArbitrageTrading_service')}</p> */}
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img className="main_service_introduce_content_img_sm" src={process.env.PUBLIC_URL+'/assets/main_section2_img2.png'}></img>
            <h1>{t('Alarm')}</h1>
            {/* <p>{t('Alarm')}</p> */}
          </div>
        </a>
        </div>
        {/* <div className="main_service_introduce_container_bg_all">
          <a href="#service_cr">
            <div className='main_service_introduce_content' style={{border:'none'}}>
              <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
              <h1>Onebot Score Chart & RSI</h1>
              <p>{t('OnebotScoreChart&RSI_service')}</p>
            </div>
          </a>
        </div> */}
      </div>
      <div className='main_service_introduce_container'>
        <div className='main_service_introduce_container_bg_w'>
        <a href="#service_dl">
          <div className='main_service_introduce_content'>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img1.png'}></img>
            <h1>Autobot Deep Lite</h1>
            <p>{t('DeepLite_service')}</p>
          </div>
        </a>
        </div>
        <div className='main_service_introduce_container_bg_b'>
        <a href="#service_dr">
          <div className='main_service_introduce_content' style={{border:'none'}}>
            <img src={process.env.PUBLIC_URL+'/assets/main_section2_img4.png'}></img>
            <h1>Autobot Deep Revolution</h1>
            <p>{t('DeepRevolution_service')}</p>
          </div>
        </a>
        </div>
      </div>
      <div className='main_service_introduce_center'>
        <img src={process.env.PUBLIC_URL+'/assets/main_service_introduce_center.png'}></img>
      </div>
    </div>
    {/* <div className={'main_section3' + sectionClass +" "+ "scroll_content"} id="service_at">
      <div className='main_section3_left_container'>
        <div>
          <h1>{t('OnebotArbitrage')}</h1>
          <p>&lt;{t('servie_at1')}&gt;</p>
          <p>{t('servie_at2')}</p>
          <p>{t('servie_at3')}</p>
        </div>
      </div>
      <div className='main_section3_right_container'>
        <img src={process.env.PUBLIC_URL+'/assets/main_section3_img.png'}></img>
      </div>
    </div> */}
    <ServiceItemCR/>
    <ServiceItemDL/>
    <ServiceItemDR/>
    </>;
  }
}
// 기존
{/* <div className={"main_service_introduce_section" + sectionClass +" "+ "scroll_content"} id="service">
<div className='main_service_introduce_container'>
  <div className='main_service_introduce_container_bg_w'>
  <a href="#service_at">
    <div className='main_service_introduce_content'>
      <img src={process.env.PUBLIC_URL+'/assets/main_section2_img1.png'}></img>
      <h1>Arbitrage Trading</h1>
      <p>{t('ArbitrageTrading_service')}</p>
    </div>
  </a>
  </div>
  <div className='main_service_introduce_container_bg_b'>
  <a href="#service_dl">
    <div className='main_service_introduce_content'>
      <img src={process.env.PUBLIC_URL+'/assets/main_section2_img2.png'}></img>
      <h1>Autobot Deep Lite</h1>
      <p>{t('DeepLite_service')}</p>
    </div>
  </a>
  </div>
</div>
<div className='main_service_introduce_container'>
  <div className='main_service_introduce_container_bg_w'>
  <a href="#service_cr">
    <div className='main_service_introduce_content' style={{border:'none'}}>
      <img src={process.env.PUBLIC_URL+'/assets/main_section2_img3.png'}></img>
      <h1>Onebot Score Chart & RSI</h1>
      <p>{t('OnebotScoreChart&RSI_service')}</p>
    </div>
  </a>
  </div>
  <div className='main_service_introduce_container_bg_b'>
  <a href="#service_dr">
    <div className='main_service_introduce_content' style={{border:'none'}}>
      <img src={process.env.PUBLIC_URL+'/assets/main_section2_img4.png'}></img>
      <h1>Autobot Deep Revolution</h1>
      <p>{t('DeepRevolution_service')}</p>
    </div>
  </a>
  </div>
</div>
<div className='main_service_introduce_center'>
  <img src={process.env.PUBLIC_URL+'/assets/main_service_introduce_center.png'}></img>
</div>
</div> */}