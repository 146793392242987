import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";

WebFont.load({
    google: {
        families: ['Anton',]
    }
})
export default function Algorithm() {

  const isTab = window.innerWidth <=768

  const { t, i18n } = useTranslation(['page']);

  let sectionClass = '';
  if (i18n.language === 'en') {
    sectionClass += ' _en';
  }
  if (i18n.language === 'jp') {
    sectionClass += ' _jp';
  }else if (i18n.language === 'cn') {
    sectionClass += ' _cn'
  }else if (i18n.language === 'ru') {
    sectionClass += ' _ru'
  } else if (i18n.language === 'vn') {
    sectionClass += ' _vn'
  }

  const isMobile = window.innerWidth <= 576;


  if(i18n.language === 'jp'){
    if(isMobile){
      return (
        <>
      <div className={'onebot_algorithm_section1_wrapper' + sectionClass }>
        <div className='onebot_algorithm_section1_left'>
          <img src={process.env.PUBLIC_URL+'/assets/algoritm_goya_icon.png'}></img>
          <h1>Goya(ゴヤ)AI</h1>
        </div>
        <div className='onebot_algorithm_section1_right'>
          <div className='onebot_algorithm_section1_right_top'>
            <p>コイン相場の変動に関する <br/><span className='text_yellow'>7年以上のビッグデータ</span>を<br/><span className='text_yellow'>ディープラーニング</span>に融合させた<span className='text_yellow'>人口知能AI</span></p>
            <p><span className='text_yellow'>毎時間コイン価格を予測</span>する<br/>チャートおよび補助指標を提供</p>
          </div>
          <div className='onebot_algorithm_section1_right_bottom'>
          {isTab ? (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_jp_mb.png'} alt="Description" />
          ) : (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_jp.png'} alt="Description" />
          )}
          </div>
      </div>
      </div>
        <div className='scroll_content'>
          <div className={'onebot_algorithm_section2_wrapper' + sectionClass }>
            <div className='onebot_algorithm_section2_container'>
              <h1>{t('onebot_algorithm')}</h1>
              <div className='onebot_algorithm_section2_flex_content'>
                <div className='onebot_algorithm_section2_content'>
                  <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img1.jpg'}></img>
                  <div><p>1</p></div>
                  <h2>{t('onebot_algorithm_section2_content1')}</h2>
                  <p>{t('onebot_algorithm_section2_content2')}</p>
                </div>
                <div className='onebot_algorithm_section2_content'>
                  <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img2.jpg'}></img>
                  <div><p>2</p></div>
                  <h2>{t('onebot_algorithm_section2_content3')}</h2>
                  <p>{t('onebot_algorithm_section2_content4')}</p>
                </div>
                <div className='onebot_algorithm_section2_content'>
                  <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img3.jpg'}></img>
                  <div><p>3</p></div>
                  <h2>{t('onebot_algorithm_section2_content5')}</h2>
                  <p>{t('onebot_algorithm_section2_content6')} <br></br>{t('onebot_algorithm_section2_content6_1')} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    }
    return (
        <>
      <div className={'onebot_algorithm_section1_wrapper' + sectionClass }>
        <div className='onebot_algorithm_section1_left'>
          <img src={process.env.PUBLIC_URL+'/assets/algoritm_goya_icon.png'}></img>
          <h1>Goya(ゴヤ)AI</h1>
        </div>
        <div className='onebot_algorithm_section1_right'>
          <div className='onebot_algorithm_section1_right_top'>
            <p>コイン相場の変動に関する <span className='text_yellow'>7年以上のビッグデータ</span>を<br/><span className='text_yellow'>ディープラーニング</span>に融合させた<span className='text_yellow'>人口知能AI</span></p>
            <p><span className='text_yellow'>毎時間コイン価格を予測</span>する<br/>チャートおよび補助指標を提供</p>
          </div>
          <div className='onebot_algorithm_section1_right_bottom'>
          {isTab ? (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_jp_mb.png'} alt="Description" />
          ) : (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_jp.png'} alt="Description" />
          )}
          </div>
      </div>
      </div>
        <div className='scroll_content'>
          <div className={'onebot_algorithm_section2_wrapper' + sectionClass }>
            <div className='onebot_algorithm_section2_container'>
              <h1>{t('onebot_algorithm')}</h1>
              <div className='onebot_algorithm_section2_flex_content'>
                <div className='onebot_algorithm_section2_content'>
                  <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img1.jpg'}></img>
                  <div><p>1</p></div>
                  <h2>{t('onebot_algorithm_section2_content1')}</h2>
                  <p>{t('onebot_algorithm_section2_content2')}</p>
                </div>
                <div className='onebot_algorithm_section2_content'>
                  <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img2.jpg'}></img>
                  <div><p>2</p></div>
                  <h2>{t('onebot_algorithm_section2_content3')}</h2>
                  <p>{t('onebot_algorithm_section2_content4')}</p>
                </div>
                <div className='onebot_algorithm_section2_content'>
                  <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img3.jpg'}></img>
                  <div><p>3</p></div>
                  <h2>{t('onebot_algorithm_section2_content5')}</h2>
                  <p>{t('onebot_algorithm_section2_content6')} <br></br>{t('onebot_algorithm_section2_content6_1')} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else if (i18n.language === 'cn') {
    return (
      <>
        <div className={'onebot_algorithm_section1_wrapper' + sectionClass }>
        <div className='onebot_algorithm_section1_left'>
          <img src={process.env.PUBLIC_URL+'/assets/algoritm_goya_icon.png'}></img>
          <h1>Goya AI</h1>
        </div>
        <div className='onebot_algorithm_section1_right'>
          <div className='onebot_algorithm_section1_right_top'>
            <p><span className='text_yellow'>有关7年以上硬币行情变动的大数据结合到<br/> 深度学习的人工智能AI</span></p>
            <p><span className='text_yellow'>提供每小时预测硬币价格的图表及<br/>辅助指标</span></p>
          </div>
          <div className='onebot_algorithm_section1_right_bottom'>
          {isTab ? (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_cn_mb.png'} alt="Description" />
          ) : (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_cn.png'} alt="Description" />
          )}
          </div>
      </div>
      </div>
      <div className='scroll_content'>
        <div className={'onebot_algorithm_section2_wrapper' + sectionClass }>
          <div className='onebot_algorithm_section2_container'>
            <h1>{t('onebot_algorithm')}</h1>
            <div className='onebot_algorithm_section2_flex_content'>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img1.jpg'}></img>
                <div><p>1</p></div>
                <h2>{t('onebot_algorithm_section2_content1')}</h2>
                <p>{t('onebot_algorithm_section2_content2')}<br></br>
                {t("onebot_algorithm_section2_content2_1")}
                </p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img2.jpg'}></img>
                <div><p>2</p></div>
                <h2>{t('onebot_algorithm_section2_content3')}</h2>
                <p>{t('onebot_algorithm_section2_content4')}
                  <br></br>{t('onebot_algorithm_section2_content4_1')}
                </p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img3.jpg'}></img>
                <div><p>3</p></div>
                <h2>{t('onebot_algorithm_section2_content5')}</h2>
                <p>{t('onebot_algorithm_section2_content6')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  }
  else if(i18n.language === "en"){
    return (
      <>
      <div className={'onebot_algorithm_section1_wrapper' + sectionClass }>
        <div className='onebot_algorithm_section1_left'>
          <img src={process.env.PUBLIC_URL+'/assets/algoritm_goya_icon.png'}></img>
          <h1>Goya AI</h1>
        </div>
        <div className='onebot_algorithm_section1_right'>
          <div className='onebot_algorithm_section1_right_top'>
            <p>An <span className='text_yellow'>artificial intelligence</span> that<br/> combines <span className='text_yellow'>deep learning</span> with<br/> big data of <span className='text_yellow'>more than 7 years<br/></span> on coin price fluctuations</p>
            <p>Provides charts and indicators <br/>that <span className='text_yellow'>predict coin prices every hour</span>
            </p>
          </div>
          <div className='onebot_algorithm_section1_right_bottom'>
          {isTab ? (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_mb.png'} alt="Description" />
          ) : (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc.png'} alt="Description" />
          )}
          </div>
        </div>
      </div>
      <div className='scroll_content'>
        <div className={'onebot_algorithm_section2_wrapper' + sectionClass }>
          <div className='onebot_algorithm_section2_container'>
            <h1>{t('onebot_algorithm')}</h1>
            <div className='onebot_algorithm_section2_flex_content'>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img1.jpg'}></img>
                <div><p>1</p></div>
                <h2>{t('onebot_algorithm_section2_content1')}</h2>
                <p>{t('onebot_algorithm_section2_content2')}</p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img2.jpg'}></img>
                <div><p>2</p></div>
                <h2>{t('onebot_algorithm_section2_content3')}</h2>
                <p>{t('onebot_algorithm_section2_content4')}</p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img3.jpg'}></img>
                <div><p>3</p></div>
                <h2>{t('onebot_algorithm_section2_content5')}</h2>
                <p>{t('onebot_algorithm_section2_content6')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  }else if(i18n.language === "ru"){
    return (
      <>
      <div className={'onebot_algorithm_section1_wrapper' + sectionClass }>
        <div className='onebot_algorithm_section1_left'>
          <img src={process.env.PUBLIC_URL+'/assets/algoritm_goya_icon.png'}></img>
          <h1>Goya AI</h1>
        </div>
        <div className='onebot_algorithm_section1_right'>
          <div className='onebot_algorithm_section1_right_top'>
            <p><span className='text_yellow'>Искусственный интеллект</span> с глубоким обучением,<br/> который сочетает<span className='text_yellow'> данные за 7 лет </span>о колебаниях <br/>цен на монеты.</p>
            {/* <p>Provides charts and indicators that <span className='text_yellow'>predict coin prices every hour</span></p> */}
          </div>
          <div className='onebot_algorithm_section1_right_bottom'>
          {isTab ? (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_ru_mb.png'} alt="Description" />
          ) : (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_ru.png'} alt="Description" />
          )}
          </div>
        </div>
      </div>
      <div className='scroll_content'>
        <div className={'onebot_algorithm_section2_wrapper' + sectionClass }>
          <div className='onebot_algorithm_section2_container'>
            <h1>{t('onebot_algorithm')}</h1>
            <div className='onebot_algorithm_section2_flex_content'>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img1.jpg'}></img>
                <div><p>1</p></div>
                <h2>{t('onebot_algorithm_section2_content1')}</h2>
                <p>{t('onebot_algorithm_section2_content2')}</p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img2.jpg'}></img>
                <div><p>2</p></div>
                <h2>{t('onebot_algorithm_section2_content3')}</h2>
                <p>{t('onebot_algorithm_section2_content4')}</p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img3.jpg'}></img>
                <div><p>3</p></div>
                <h2>{t('onebot_algorithm_section2_content5')}</h2>
                <p>{t('onebot_algorithm_section2_content6')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  }else if(i18n.language === "vn"){
    return (
      <>
      <div className={'onebot_algorithm_section1_wrapper' + sectionClass }>
        <div className='onebot_algorithm_section1_left'>
          <img src={process.env.PUBLIC_URL+'/assets/algoritm_goya_icon.png'}></img>
          <h1>Goya AI</h1>
        </div>
        <div className='onebot_algorithm_section1_right'>
          <div className='onebot_algorithm_section1_right_top'>
            <p>Dữ liệu lớn<span className='text_yellow'> hơn 7 năm</span> liên quan đến biến động giá đồng xu<br/><span className='text_yellow'>AI trí tuệ nhân tạo </span> được kết hợp với<span className='text_yellow'> deep learning</span></p>
            <p><span className='text_yellow'>Dự đoán giá đồng xu mỗi giờ<br/></span>Cung cấp bảng xếp hạng và chỉ số hỗ trợ</p>
          </div>
          <div className='onebot_algorithm_section1_right_bottom'>
          {isTab ? (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_vn_mb.png'} alt="Description" />
          ) : (
            <img src={process.env.PUBLIC_URL + '/assets/algoritm_desc_vn.png'} alt="Description" />
          )}
          </div>
        </div>
      </div>
      <div className='scroll_content'>
        <div className={'onebot_algorithm_section2_wrapper' + sectionClass }>
          <div className='onebot_algorithm_section2_container'>
            <h1>{t('onebot_algorithm')}</h1>
            <div className='onebot_algorithm_section2_flex_content'>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img1.jpg'}></img>
                <div><p>1</p></div>
                <h2>{t('onebot_algorithm_section2_content1')}</h2>
                <p>{t('onebot_algorithm_section2_content2')}</p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img2.jpg'}></img>
                <div><p>2</p></div>
                <h2>{t('onebot_algorithm_section2_content3')}</h2>
                <p>{t('onebot_algorithm_section2_content4')}</p>
              </div>
              <div className='onebot_algorithm_section2_content'>
                <img src={process.env.PUBLIC_URL+'/assets/onebot_algorithm_img3.jpg'}></img>
                <div><p>3</p></div>
                <h2>{t('onebot_algorithm_section2_content5')}</h2>
                <p>{t('onebot_algorithm_section2_content6')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  }
}